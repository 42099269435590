<template>
    <Modal @close="isCloseDisabled ? null : handleCloseModal" v-if="isModalQrShown">
      <template v-slot:body>
        <div class="modal-qr">
          <qrcode-vue
            class="qr"
            :value="code || ''"
            :size="240"
            level="H"
          />
          <template v-if="isDownloadModalShown">
            <div class="text-center qr-name">
              {{ name }}
            </div>
          </template>
          <template v-else>
            <div class="qr-code">
              <p class="qr-title">店舗コード</p>
              <p class="qr-num">{{ code }}</p>
            </div>
            <div class="qr-code">
              <p class="qr-title">店舗名</p>
              <p class="qr-num">{{ name }}</p>
            </div>
          </template>
        </div>
      </template>
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item">
            <button
              class="btn btn-white"
              type="button"
              @click="handleCloseModal"
            >
              閉じる
            </button>
          </li>
          <li class="listGrid-item" v-if="isDownloadModalShown">
            <button
              class="btn btn-main"
              type="button"
              @click="handleDownloadQr(name)"
            >
              保存
            </button>
          </li>
        </ul>
      </template>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import modal from '@/mixins/plugin/modal';
import QrcodeVue from 'qrcode.vue';
import html2canvas from 'html2canvas';

export default {
  components: {
    Modal,
    QrcodeVue
  },
  mixins: [modal],
  props: {
    code: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    handleCloseModal: {
      type: Function,
      required: true
    },
    isModalQrShown: {
      type: Boolean,
      default: false,
    },
    isDownloadModalShown: {
      type: Boolean,
      default: false,
    },
    isCloseDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleDownloadQr(name) {
      const container = document.querySelector(".modal-qr");
      const canvas = document.createElement("canvas");
      canvas.width = container.offsetWidth;
      canvas.height = container.offsetHeight;
      html2canvas(container).then(function (canvas) {
        const image = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.href = image;
        downloadLink.download = `${name}.png`;
        downloadLink.click();
      });
    },
  }
}
</script>

<style scoped>
.qr-name {
  font-weight: bold;
  font-size: 18px;
}

.modal-qr {
  padding: 10px 0;
}
</style>
