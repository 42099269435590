import { StatusConstants } from '@/constants/status';

export default {
  data() {
    return {
      shopStatusList: StatusConstants.shop,
    }
  },
  computed: {
    statusClass: function() {
      return function(status) {
        switch (status) {
          case '公開中':
          case '承認済み':
            return 'status-green';
          case '期間終了':
          case '解約':
            return 'status-gray';
          case '一時停止':
          case '否決':
            return 'status-red';
          case '公開準備中':
          case '保留':
            return 'status-yellow';
          case '未登録':
          case '審査待ち':
            return 'status-purple';
          case '予算達成':
            return 'status-blue';
        }
        return false;
      };
    },
  },
  methods: {
    getShopStatusName(statusId) {
      return Object.values(this.shopStatusList).find(item => item.value === statusId).label;
    },
  }
};
